<template>
  <b-container>
    <b-row v-if="loading">
      <b-col class="cols-6 d-flex justify-content-center mt-10">
        <span
          class="spinner-border spinner-border-lg center-spinner text-primary m-auto"
        ></span>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12 col-sm-10 col-lg-6 mx-auto">
        <div v-if="!loading" class="card mt-10">
          <PulsCardHeader
            :title="$t('user.header')"
            :leftAction="{
              to: { name: 'overview', title: $t('user.button.skip') }
            }"
          />
          <b-card-body>
            <b-row v-for="(attribute, name) in defaultAttributes" :key="name">
              <b-col
                v-if="!attribute.isHidden && attributeNameShouldBeVisible(name)"
              >
                <div
                  v-if="attribute.type === 'string'"
                  class="d-flex align-items-center mb-3"
                >
                  <i :class="`bi bi-${attribute.icon} fs-2 pt-1 me-2`"></i>
                  <PulsFormInput
                    class="flex-grow-1 me-6"
                    :placeholder="attribute.placeholder"
                    v-model="attribute.value"
                  />
                </div>

                <div
                  v-if="attribute.type === 'select'"
                  class="d-flex align-items-center mb-3"
                >
                  <i
                    v-if="attribute.icon !== 'account_box'"
                    :class="`bi bi-${attribute.icon} fs-2 pt-2 me-2`"
                  ></i>
                  <i
                    v-else
                    :class="`bi bi-patch-question-fill fs-2 pt-2 me-2`"
                  ></i>
                  <PulsFormSelect
                    class="flex-grow-1"
                    :options="attribute.options"
                    v-model="attribute.value"
                  />
                  <i
                    :id="name + '-help'"
                    v-if="attribute.info"
                    class="bi bi-question fs-3 text-info md-18 help-icon"
                  ></i>
                  <b-tooltip
                    :target="name + '-help'"
                    placement="top"
                    triggers="hover blur"
                    delay="100"
                  >
                    {{ attribute.info }}
                  </b-tooltip>
                </div>
              </b-col>
            </b-row>

            <div v-if="error">
              <p class="mt-3 ml-5 text-danger">{{ error.message }}</p>
            </div>
          </b-card-body>

          <b-card-footer class="d-flex justify-content-end align-items-start">
            <b-button
              class="me-5"
              variant="secondary"
              @click="cancel"
              type="submit"
              :disabled="!changesHaveBeenMade"
            >
              <i class="bi bi-x-circle-fill"></i>
              {{ $t('user.button.cancel') }}
            </b-button>
            <b-button
              variant="primary"
              @click="save"
              type="submit"
              :disabled="!changesHaveBeenMade"
            >
              <i class="bi bi-save-fill"></i>
              {{ $t('user.button.submit') }}
            </b-button>
          </b-card-footer>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  PulsFormSelect,
  PulsFormInput,
  PulsCardHeader
} from '@puls-solutions/puls-ui-components'

import { saveAttributes } from '../services/AwsAmplifyService'

export default {
  name: 'User',
  components: {
    PulsCardHeader,
    PulsFormInput,
    PulsFormSelect
  },
  data() {
    return {
      defaultAttributes: {
        given_name: {
          icon: 'person-fill',
          type: 'string',
          placeholder: this.$t('user.attributes.given_name.label'),
          value: null
        },
        family_name: {
          icon: 'person-fill',
          type: 'string',
          placeholder: this.$t('user.attributes.family_name.label'),
          value: null
        },
        locale: {
          icon: 'globe',
          type: 'select',
          options: [
            { value: null, text: this.$t('user.attributes.locale.label') },
            { value: 'ar', text: this.$t('user.attributes.locale.ar') },
            { value: 'da', text: this.$t('user.attributes.locale.da') },
            { value: 'de', text: this.$t('user.attributes.locale.de') },
            { value: 'en', text: this.$t('user.attributes.locale.en') },
            { value: 'es', text: this.$t('user.attributes.locale.es') },
            { value: 'et', text: this.$t('user.attributes.locale.et') },
            { value: 'fi', text: this.$t('user.attributes.locale.fi') },
            { value: 'fr', text: this.$t('user.attributes.locale.fr') },
            { value: 'hr', text: this.$t('user.attributes.locale.hr') },
            { value: 'it', text: this.$t('user.attributes.locale.it') },
            { value: 'ms', text: this.$t('user.attributes.locale.ms') },
            { value: 'nl', text: this.$t('user.attributes.locale.nl') },
            { value: 'no', text: this.$t('user.attributes.locale.no') },
            { value: 'pl', text: this.$t('user.attributes.locale.pl') },
            { value: 'ru', text: this.$t('user.attributes.locale.ru') },
            { value: 'so', text: this.$t('user.attributes.locale.so') },
            { value: 'sr', text: this.$t('user.attributes.locale.sr') },
            { value: 'sv', text: this.$t('user.attributes.locale.sv') },
            { value: 'uk', text: this.$t('user.attributes.locale.uk') },
            { value: 'zh', text: this.$t('user.attributes.locale.zh') }
          ],
          info: this.$t('user.attributes.locale.info'),
          value: null
        }
      },
      localesMap: {
        ar: 'ar-SA',
        da: 'da-DK',
        de: 'de-DE',
        en: 'en-US',
        es: 'es-ES',
        et: 'et-EE',
        fi: 'fi-FI',
        fr: 'fr-FR',
        hr: 'hr-HR',
        it: 'it-IT',
        ms: 'ms-MY',
        nl: 'nl-NL',
        no: 'no-NO',
        pl: 'pl-PL',
        ru: 'ru-RU',
        so: 'so-SO',
        sv: 'sv-SE',
        sr: 'sr-RS',
        uk: 'uk-UA',
        zh: 'zh-CN'
      }
    }
  },
  mounted() {
    this.setLocalesToChooseBetween()
    this.updateAttributesFromUserAttributes(this.userAttributes) // set what we get from server
    this.reRenderAttributesForLocale()
    if (
      this.defaultAttributes.locale.value &&
      this.defaultAttributes.locale.value.length &&
      this.$i18n.locale !== this.defaultAttributes.locale.value
    ) {
      this.setLocale(this.defaultAttributes.locale.value)
    }
  },
  computed: {
    changesHaveBeenMade() {
      let changesMade = false
      Object.keys(this.defaultAttributes)
        .filter((key) => this.defaultAttributes[key].value)
        .forEach((key) => {
          let value = this.defaultAttributes[key].value
          if (key === 'locale') {
            value = this.localesMap[this.defaultAttributes[key].value]
            if (!value) {
              throw new Error(`Unsupported locale: ${key}`)
            }
          }
          if (!this.userAttributes[key] || this.userAttributes[key] !== value) {
            changesMade = true
          }
        })

      return changesMade
    },
    customLocalesAvailable() {
      if (this.orgData.orgSettings && this.orgData.orgSettings.locales) {
        return this.orgData.orgSettings.locales
      } else {
        return null
      }
    },
    error() {
      return this.$store.getters.userDetailsError
    },
    loading() {
      return this.$store.getters.userDetailsLoading
    },
    organizationConfiguredAttributes() {
      if (
        window.localStorage.orgData &&
        JSON.parse(window.localStorage.orgData).attributes
      ) {
        return JSON.parse(window.localStorage.orgData).attributes
      } else {
        return []
      }
    },
    orgData() {
      if (window.localStorage.orgData) {
        return JSON.parse(window.localStorage.orgData)
      } else {
        return null
      }
    },
    selectedLocale() {
      return this.defaultAttributes.locale.value
    },
    userAttributes() {
      return this.$store.getters.loggedInUser.signInUserSession.idToken.payload
    }
  },
  watch: {
    selectedLocale(newLocale, oldLocale) {
      if (newLocale && newLocale !== oldLocale) {
        this.setLocale(newLocale)
        this.reRenderAttributesForLocale()
      }
    }
  },
  methods: {
    reRenderAttributesForLocale() {
      const attributes = {}
      Object.keys(this.defaultAttributes).forEach((key) => {
        const attribute = this.defaultAttributes[key]
        if (attribute.placeholder) {
          this.defaultAttributes[key].placeholder = this.$t(
            `user.attributes.${key}.label`
          )
        }
        if (attribute.info) {
          this.defaultAttributes[key].info = this.$t(
            `user.attributes.${key}.info`
          )
        }
        if (attribute.options) {
          attribute.options.forEach((option, i) => {
            if (option.value !== null) {
              this.defaultAttributes[key].options[i].text = this.$t(
                `user.attributes.${key}.${option.value}`
              )
            } else {
              this.defaultAttributes[key].options[i].text = this.$t(
                `user.attributes.${key}.label`
              )
            }
          })
        }
        attributes[key] = attribute
      })
      this.defaultAttributes = attributes
    },
    setLocalesToChooseBetween() {
      this.defaultAttributes.locale.options =
        this.defaultAttributes.locale.options.filter((option) =>
          this.filterForOption(option)
        )
    },
    filterForOption(option) {
      if (
        this.customLocalesAvailable &&
        Object.keys(this.customLocalesAvailable).length
      ) {
        return Object.keys(this.customLocalesAvailable).includes(option.value)
      } else {
        return true // keep option
      }
    },
    setLocale(locale) {
      window.localStorage.setItem('selectedLocale', locale)
      this.$i18n.locale = locale
    },
    attributeNameShouldBeVisible(attributeName) {
      return (
        this.organizationConfiguredAttributes.length === 0 || // no custom attributes found
        !this.organizationConfiguredAttributes
          .map((a) => a.name)
          .includes(attributeName) || // or attribute is not one of the custom attributes
        (this.organizationConfiguredAttributes
          .map((a) => a.name)
          .includes(attributeName) && // or it is, and it is enabled
          this.organizationConfiguredAttributes.filter(
            (a) => a.name === attributeName
          )[0].data.enabled)
      )
    },
    save() {
      if (this.changesHaveBeenMade) {
        const attributes = this.selectedAttributes()
        saveAttributes(attributes, this.$store, this.$router)
      } else {
        this.$router.push('/overview')
      }
    },
    cancel(e) {
      if (e) {
        e.preventDefault()
      }
      this.clearAttributesThatWereNotSetFromStart(this.userAttributes)
      this.updateAttributesFromUserAttributes(this.userAttributes)
      this.reRenderAttributesForLocale()
    },
    updateAttributesFromUserAttributes(userAttributes) {
      Object.keys(userAttributes).forEach((userAttribute) => {
        if (Object.keys(this.defaultAttributes).includes(userAttribute)) {
          if (userAttribute === 'locale') {
            const foundLocaleKeys = Object.keys(this.localesMap).filter(
              (locale) =>
                this.localesMap[locale] === userAttributes[userAttribute]
            )
            if (foundLocaleKeys.length) {
              const foundLocale = foundLocaleKeys[0]
              this.defaultAttributes[userAttribute].value = foundLocale
            } else {
              // invalid locale found, fallback to 'en'
              this.defaultAttributes[userAttribute].value = 'en'
            }
          } else {
            this.defaultAttributes[userAttribute].value =
              userAttributes[userAttribute]
          }
        }
      })
    },
    clearAttributesThatWereNotSetFromStart(userAttributes) {
      const userAttributeKeys = Object.keys(userAttributes)
      Object.keys(this.defaultAttributes)
        .filter((key) => !userAttributeKeys[key])
        .forEach((key) => {
          this.defaultAttributes[key].value = null
        })
    },
    // Collect all attributes that have values and reformat as needed
    selectedAttributes() {
      const attributes = {}
      Object.keys(this.defaultAttributes)
        .filter((key) => this.defaultAttributes[key].value)
        .forEach((key) => {
          const attribute = this.defaultAttributes[key]
          let value = attribute.value
          if (attribute.type === 'date') {
            if (attribute.value.toISOString) {
              value = attribute.value.toISOString().split('T')[0]
            }
          }
          if (key === 'locale') {
            value = this.localesMap[attribute.value]
          }
          attributes[key] = value
        })

      return attributes
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0;
}

.center-spinner {
  margin: 0 auto !important;
  float: none !important;
}

::-webkit-input-placeholder,
::-moz-placeholder {
  margin-left: 10px !important;
}

@media (max-width: 340px) {
  .date-input {
    width: 120px;
  }

  .form-body {
    margin-left: 0px;
    padding-left: 0px;
  }
}
</style>

<style>
.help-icon {
  cursor: help;
}
</style>
